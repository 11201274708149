<template>
  <div v-if="getMqttIsInstanceConnected">
    <div v-if="isPageEnabled">
      <div v-if="getStatusPage" :style="configStyle">
        <div v-if="2 != getMode">
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                Smart Light Controller supports 3 players:
                <li>Generic</li>
                <li>Sequence</li>
                <li>DDP</li>
                Generic Player provides the basic functionality to turn the
                light on and off. <br />Sequence Player enables advanced control
                by playing frame sequences to create light shows accompanied
                with music. <br />DDP (Dynamic Display Protocol) player
                facilitates the creation and playback of light shows designed
                with a PC sequencer.<br />
                <hr />
                Please select the desired player from the drop-down menu below.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                value="Player Configuration"
                readonly
              />
            </div>
          </Popper>
          <div>
            <select
              class="selector"
              v-model="selectedCtrlMode"
              @change="onCtrlModeChange($event)"
            >
              <option
                v-for="index in getCtrlModes"
                :value="index.value"
                :key="index"
              >
                {{ index.text }}
              </option>
              <!--
          <option value="255">Generic</option>
          <option value="253">Sequence</option>
          <option value="252">DDP</option>
          -->
            </select>
          </div>
          <!-- SEQUENCE PLAYER  BEGIN -->
          <div v-if="253 == selectedCtrlMode">
            <Popper
              class="labelStyle"
              style="position: relative; width: 100%"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Activation in the context of Sequence Player refers to the
                  scenario that triggers an action to play a sequence.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Activation"
                  readonly
                />
              </div>
            </Popper>
            <div>
              <select
                class="selector"
                v-model="selectedActivation"
                @change="onActivationChange($event)"
              >
                <option value="1">Scenario #1</option>
                <option value="2">Scenario #2</option>
                <option value="3">Scenario #3</option>
                <option value="4">Scenario #4</option>
                <option value="5">Scenario #5</option>
                <option value="6">Scenario #6</option>
                <option value="7">Scenario #7</option>
                <option value="8">Scenario #8</option>
                <option value="9">Scenario #9 (OFF)</option>
              </select>
            </div>
            <Popper
              class="labelStyle"
              style="position: relative; width: 100%"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Sequence File contains a series of frames to display a light
                  show.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Sequence File"
                  readonly
                />
              </div>
            </Popper>
            <div>
              <select
                class="selector"
                v-model="selectedSequenceFile"
                @change="onSequenceFileChange($event)"
              >
                <option
                  v-for="index in getSequenceFiles"
                  :value="index.value"
                  :key="index"
                >
                  {{ index.text }}
                </option>
              </select>
            </div>
            <Popper
              class="labelStyle"
              style="position: relative; width: 100%"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Media File includes an audio track which serves as the
                  soundtrack for the light show.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Media File"
                  readonly
                />
              </div>
            </Popper>
            <div>
              <select
                class="selector"
                v-model="selectedMediaFile"
                @change="onMediaFileChange($event)"
              >
                <option
                  v-for="index in getMediaFiles"
                  :value="index.value"
                  :key="index"
                >
                  {{ index.text }}
                </option>
              </select>
            </div>
            <Popper
              class="labelStyle"
              style="position: relative; width: 100%"
              arrow
              offsetDistance="0"
            >
              <template #content>
                <div>
                  Volume adjusts the sound level of a media file for a light
                  show.
                </div>
              </template>
              <div class="label" style="width: 100%">
                <i class="bx bx-info-circle" />
                <input
                  type="text"
                  class="textlabel"
                  value="Volume Help"
                  readonly
                />
              </div>
            </Popper>
            <div>
              <select
                class="selector"
                v-model="selectedVolume"
                @change="onVolumeChange($event)"
              >
                <option value="0">Silent</option>
                <option value="1">Quiet</option>
                <option value="2">Medium</option>
                <option value="3">Loud</option>
              </select>
            </div>
          </div>
          <!-- SEQUENCE PLAYER  END -->
        </div>
        <!-- REGISTRATION  START -->
        <div v-if="2 == getMode">
          <Popper
            class="labelStyle"
            style="position: relative; width: 100%"
            arrow
            offsetDistance="0"
          >
            <template #content>
              <div>
                To start the registration, the registrar should submit token and
                credentials.
              </div>
            </template>
            <div class="label" style="width: 100%">
              <i class="bx bx-info-circle" />
              <input
                type="text"
                class="textlabel"
                :value="getRegistrationStatus"
                readonly
              />
            </div>
          </Popper>
          <form @submit.prevent="onSubmit">
            <div>
              <input
                class="input"
                style="width: 100%"
                id="token"
                v-model="form.token"
                placeholder="Token"
                type="text"
                autocomplete="on"
                required
              />
            </div>
            <div>
              <input
                class="input"
                style="width: 100%"
                id="name"
                v-model="form.name"
                placeholder="Name"
                type="text"
                autocomplete="on"
                required
              />
            </div>
            <div>
              <input
                class="input"
                style="width: 100%"
                id="passord"
                v-model="form.password"
                placeholder="Password"
                type="password"
                autocomplete="on"
                required
              />
            </div>
            <!-- </fieldset> -->
            <div>
              <input
                type="submit"
                class="input"
                style="width: 100%"
                value="Submit"
              />
            </div>
          </form>
        </div>
        <!-- REGISTRATION  END -->
        <button
          id="button"
          class="button"
          v-if="apply"
          @:click="handleApplyClick"
        >
          Apply
        </button>
      </div>
      <div v-else :style="pageStyle">Applying...</div>
    </div>
    <div v-else :style="pageStyle">Disabled</div>
  </div>
  <div v-else :style="pageStyle">
    {{ connectionMsg }}
  </div>
</template>

<script>
// @ is an alias to /src
import AppConstants from "@/AppConstants.js";
import { mapGetters, mapMutations } from "vuex";
import Popper from "vue3-popper";
const ACTIVATION_MIN_NUMBER = 1;
const ACTIVATION_MAX_NUMBER = 9;
export default {
  name: "Config",
  data: function () {
    let size = AppConstants.get2Size();
    return {
      selectedCtrlMode: 0,
      selectedActivation: 1,
      selectedSequenceFile: 0,
      selectedMediaFile: 0,
      selectedVolume: 0,
      apply: false,
      isPageEnabled: true,
      configStyle: {
        position: "absolute",
        background: "black",
        border: "1px solid black",
        left: AppConstants.MENU_WIDTH + "px",
        top: "0vh",
        width: size.width + "vw",
        height: "100vh",
        color: "white",
      },
      pageStyle: {
        position: "absolute",
        background: "black",
        border: "3px solid gray",
        left: AppConstants.MENU_WIDTH + "px",
        width: size.width + "vw",
        color: "white",
        fontSize: "x-large",
        margin: "auto",
        textAlign: "center",
        padding: "10px",
      },
      form: {
        password: "",
        name: "",
      },
    };
  },
  components: {
    Popper,
  },
  computed: {
    ...mapGetters({
      mqttInstance: "mqttStore/getMqttInstance",
      getMqttUserTopic: "mqttStore/getMqttUserTopic",
      getMqttIsInstanceConnected: "mqttStore/getMqttIsInstanceConnected",
      getMode: "configStore/getMode",
      getCtrlMode: "configStore/getCtrlMode",
      getCtrlModes: "configStore/getCtrlModes",
      getUpdateCounter: "configStore/getUpdateCounter",
      getSequenceFiles: "configStore/getSequenceFiles",
      getMediaFiles: "configStore/getMediaFiles",
      getSequenceByIndex: "configStore/getSequenceByIndex",
      getSequenceFileByIndex: "configStore/getSequenceFileByIndex",
      getMediaByIndex: "configStore/getMediaByIndex",
      getMediaFileByIndex: "configStore/getMediaFileByIndex",
      getVolumeByIndex: "configStore/getVolumeByIndex",
      getStatusPage: "configStore/getStatusPage",
      getRegistrationStatus: "configStore/getRegistrationStatus",
    }),
    connectionMsg() {
      return "Waiting for MQTT connection...";
    },
  },
  methods: {
    ...mapMutations({
      setSequence: "configStore/setSequence",
      setMedia: "configStore/setMedia",
      setVolume: "configStore/setVolume",
      setStatusPage: "configStore/setStatusPage",
      setRegistrationStatus: "configStore/setRegistrationStatus",
    }),
    onCtrlModeChange: function (event) {
      this.selectedCtrlMode = event.target.value;
      this.apply = true;
    },
    onActivationChange: function (event) {
      this.selectedActivation = event.target.value;
      this.selectedSequenceFile = this.getSequenceByIndex(
        this.selectedActivation
      );
      this.selectedMediaFile = this.getMediaByIndex(this.selectedActivation);
      this.selectedVolume = this.getVolumeByIndex(this.selectedActivation);
      this.apply = true;
    },
    onSequenceFileChange: function (event) {
      let val = { index: Number, value: Number };
      this.selectedSequenceFile = event.target.value;
      val.index = this.selectedActivation;
      val.value = this.selectedSequenceFile;
      this.setSequence(val);
      this.apply = true;
    },
    onMediaFileChange: function (event) {
      let val = { index: Number, value: Number };
      this.selectedMediaFile = event.target.value;
      val.index = this.selectedActivation;
      val.value = this.selectedMediaFile;
      this.setMedia(val);
      this.apply = true;
    },
    onVolumeChange: function (event) {
      let val = { index: Number, value: Number };
      this.selectedVolume = event.target.value;
      val.index = this.selectedActivation;
      val.value = this.selectedVolume;
      this.setVolume(val);
      this.apply = true;
    },
    handleApplyClick: function () {
      let ndx;
      let message = "{";
      message += "'name':'config'";
      message += ",'mode':'set'";
      message += ",'ctrlmode':" + this.selectedCtrlMode;
      /* Selected Sequence Array */
      message += ",'selectedSequenceFileArray': [";
      for (ndx = ACTIVATION_MIN_NUMBER; ACTIVATION_MAX_NUMBER >= ndx; ndx++) {
        message += "'";
        message += this.getSequenceFileByIndex(ndx);
        message += "'";
        if (ACTIVATION_MAX_NUMBER > ndx) message += ",";
      }
      message += "]";
      /* Selected Media Array */
      message += ",'selectedMediaFileArray': [";
      for (ndx = ACTIVATION_MIN_NUMBER; ACTIVATION_MAX_NUMBER >= ndx; ndx++) {
        message += "'";
        message += this.getMediaFileByIndex(ndx);
        message += "'";
        if (ACTIVATION_MAX_NUMBER > ndx) message += ",";
      }
      message += "]";
      /* Volume Array */
      message += ",'volumeArray': [";
      for (ndx = ACTIVATION_MIN_NUMBER; ACTIVATION_MAX_NUMBER >= ndx; ndx++) {
        message += this.getVolumeByIndex(ndx).toString();
        if (ACTIVATION_MAX_NUMBER > ndx) message += ",";
      }
      message += "]";
      /* -- */
      message += "}";
      if (this.getMqttIsInstanceConnected) {
        this.mqttInstance.publish(
          this.getMqttUserTopic + "/update",
          message,
          0,
          {},
          () => {}
        );
        this.setStatusPage(0);
      }
      this.apply = false;
    },
    onSubmit() {
      let message = "{";
      message += "'token':'" + this.form.token + "'";
      message += ",'name':'" + this.form.name + "'";
      message += ",'password':'" + this.form.password + "'";
      message += "}";
      /* -- */
      if (this.getMqttIsInstanceConnected) {
        this.mqttInstance.publish(
          this.getMqttUserTopic + "/registrar/request",
          message,
          0,
          {},
          () => {}
        );
      } else {
        this.setRegistrationStatus("No connection");
      }
    },
  },
  created() {
    if (AppConstants.CONFIG_INSTALLATION_MODE == this.getMode) {
      this.isPageEnabled = false;
    }
    this.setRegistrationStatus("Registration");
    let message = "{";
    message += "'name':'config'";
    message += ",'mode':'get'";
    message += "}";
    if (this.getMqttIsInstanceConnected) {
      this.mqttInstance.publish(
        this.getMqttUserTopic + "/update",
        message,
        0,
        {},
        () => {}
      );
    }
  },
  mounted() {},
  watch: {
    getUpdateCounter: function () {
      this.selectedCtrlMode = this.getCtrlMode;
      this.selectedActivation = 1;
      this.selectedSequenceFile = this.getSequenceByIndex(
        this.selectedActivation
      );
      this.selectedMediaFile = this.getMediaByIndex(this.selectedActivation);
      this.selectedVolume = this.getVolumeByIndex(this.selectedActivation);
      this.setStatusPage(1);
    },
  },
};
</script>
<style scoped>
.input {
  color: white;
  background-color: black;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 1px;
  border-radius: 25px;
  text-align: center;
}
.input:hover {
  border: 3px solid white;
}
.input:focus {
  border: 3px solid white;
  outline: none;
}
.label {
  position: relative;
  color: gray;
  background-color: black;
  caret-color: transparent;
  font-size: large;
  margin: 1vh 0vw 1vh 0vw;
  border: 3px solid #303030;
  padding: 1px;
  border-radius: 25px;
  text-align: center;
}
.label:hover {
  border: 3px solid white;
}
.label:focus {
  border: 3px solid white;
  outline: none;
}
.button {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  text-decoration: none;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  width: 100%;
  margin: 1vh 0vw 1vh 0vw;
  padding: 10px;
  border-radius: 25px;
}
.button:hover {
  border: 3px solid white;
}
.selector {
  color: white;
  border: 3px solid #303030;
  background-color: black;
  text-align: center;
  font-size: large;
  transition-duration: 0.4s;
  cursor: pointer;
  position: center;
  margin: 1vh 0vw 1vh 0vw;
  padding: 1px;
  border-radius: 25px;
  width: 100%;
}
.selector:focus {
  border: 3px solid #303030;
  outline: none;
}
.selector:hover {
  border: 3px solid white;
}
.labelStyle {
  position: relative;
  text-align: left;
  float: left;
  --popper-theme-background-color: white;
  --popper-theme-background-color-hover: white;
  --popper-theme-text-color: black;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 25px;
  --popper-theme-padding: 10px;
}
.separator {
  height: 1px;
  background-color: white;
  margin: 10px 0;
}
.textlabel {
  color: gray;
  background-color: black;
  font-size: large;
  margin: 0vh 1vw 0vh 1vw;
  border: none;
  outline: none;
  text-align: center;
}
</style>
