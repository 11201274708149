const MENU_WIDTH = 78;
const DASHBOARD_PANEL_STRIP_HEIGHT = 40;
const SETTING_PANEL_STRIP_HEIGHT = 40;
const COLOR_PANEL_COMPONENT_HEIGHT = 33;
const NUMBER_OF_STRIPS = 8;
const NUMBER_OF_BUTTONS = NUMBER_OF_STRIPS;
const NUMBER_OF_SENSORS = 16;
const DEFAULT_COLOR = "#303030";
/* 
     CONFIG_REMOTE_ACCESS == false, the remote access  is disabled. Only in registration and global mde. 
     CONFIG_REMOTE_ACCESS == true,  the remote access  is enabled.
*/
const CONFIG_REMOTE_ACCESS = false;
/* 
    CONFIG_NETWORK_PAGE_MODE == false, the page is disabled. Typically, it is used for cloud version of GUI. 
    CONFIG_NETWORK_PAGE_MODE == true, the page is enabled. Typically, it is used for local version of GUI. 
*/
const CONFIG_NETWORK_PAGE_MODE = false;
/* 
    CONFIG_TOF_SENSOR_MODE == false, the TOF mode is disabled. Typically, it is used for cloud version of GUI. 
    CONFIG_TOF_SENSOR_MODE == true, the TOF mode is enabled. Typically, it is used for local version of GUI. 
*/
const CONFIG_TOF_SENSOR_MODE = false;
/* 
     CONFIG_MQTT_LOGIN_MODE == false, the login page is disabled. Typically, it is used for local connection. 
     CONFIG_MQTT_LOGIN_MODE == true, the login page is enabled. Typically, it is used for global connection. 
*/
const CONFIG_MQTT_LOGIN_MODE = true;
/* -- */
const CONFIG_PRIMARY_MODE = 0;
const CONFIG_INSTALLATION_MODE = 1;
const CONFIG_REGISTRATION_MODE = 2;
const CONFIG_MODE = CONFIG_PRIMARY_MODE;
/* -- */
function getWidth() {
  let width;
  /*
  let windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
*/
  let windowHeight = screen.height;
  let halfHeight = windowHeight / 2;
  if (screen.width > windowHeight) {
    width = halfHeight;
  } else {
    width = screen.width - MENU_WIDTH;
    if (width > halfHeight) width = halfHeight;
  }
  width = (width * 100) / windowHeight;
  return width;
}
function get2Size() {
  let size = { width: 0, height: 0, widthpx: 0 };
  let width, height;
  let windowHeight = screen.height;
  let halfHeight = windowHeight / 2;
  if (screen.width > windowHeight) {
    width = halfHeight;
    height = width;
  } else {
    width = screen.width - MENU_WIDTH;
    let threshold = 2 * width;
    if (windowHeight >= threshold) {
      height = width;
    } else {
      height = windowHeight - width;
    }
  }
  size.widthpx = width;
  size.width = (width * 100) / screen.width;
  size.height = (0.8 * height * 100) / screen.height;
  return size;
}
export default {
  MENU_WIDTH: MENU_WIDTH,
  DASHBOARD_PANEL_STRIP_HEIGHT: DASHBOARD_PANEL_STRIP_HEIGHT,
  SETTING_PANEL_STRIP_HEIGHT: SETTING_PANEL_STRIP_HEIGHT,
  NUMBER_OF_STRIPS: NUMBER_OF_STRIPS,
  NUMBER_OF_BUTTONS: NUMBER_OF_BUTTONS,
  NUMBER_OF_SENSORS: NUMBER_OF_SENSORS,
  COLOR_PANEL_COMPONENT_HEIGHT: COLOR_PANEL_COMPONENT_HEIGHT,
  DEFAULT_COLOR: DEFAULT_COLOR,
  getWidth: getWidth,
  get2Size: get2Size,
  /* -- */
  CONFIG_REMOTE_ACCESS,
  CONFIG_MQTT_LOGIN_MODE,
  CONFIG_PRIMARY_MODE,
  CONFIG_INSTALLATION_MODE,
  CONFIG_REGISTRATION_MODE,
  CONFIG_MODE,
  CONFIG_NETWORK_PAGE_MODE,
  CONFIG_TOF_SENSOR_MODE,
};
